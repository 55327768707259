import {
  fetchGetInfrastructure,
  TGetInfrastructureRequest,
  TGetInfrastructureResponse_1,
} from 'shared/common/repositories/infrastructure-caching/v4/get-infrastructure';
import { restApiFunctionWrapper } from 'shared/common/utils/restApiWrapper/restApiWrapper';

export const fetchInfrastructure = restApiFunctionWrapper<TGetInfrastructureRequest, TGetInfrastructureResponse_1>(
  fetchGetInfrastructure,
);
