import { Map as MapRef } from '@cian/frontend-newbuilding-map-component';
import { IGeoObject } from '@cian/frontend-newbuilding-map-component/es/types';
import { IControl } from '@cian/frontend-newbuilding-map-component/es/types/control';
import { IGeoObjectsFetchData } from '@cian/frontend-newbuilding-map-component/es/types/fetch';
import { useIsDesktop } from '@cian/valuation-utils-component';
import { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { TGetInfrastructureResponse_1 } from 'shared/common/repositories/infrastructure-caching/v4/get-infrastructure';
import { IInfrastructureProps } from 'shared/mf-infrastructure/containers/InfrastructureContainer/types';
import { useWidgetSettingsContext } from 'shared/mf-infrastructure/containers/WidgetSettingsContext';
import { useAppDispatch } from 'shared/mf-infrastructure/hooks';
import { transformSimilarObjectsToGeoObjects } from 'shared/mf-infrastructure/mappers/transformGetAjaxMapRoundaboutResponse';
import { transformNewbuildingInfrastructure } from 'shared/mf-infrastructure/mappers/transformGetInfrastructureV3Response/transformNewbuildingInfrastructure';
import { transformResultToGeoObjects } from 'shared/mf-infrastructure/mappers/transformGetInfrastructureV3Response/transformResultToGeoObjects';
import { transformSimilarNewbuildingsToGeoObjects } from 'shared/mf-infrastructure/mappers/transformGetRecommendationsNearNewbuildingResponse';
import {
  selectAvailableInfrastructureTypes,
  selectInfrastructureApiResult,
  selectInfrastructureStatus,
} from 'shared/mf-infrastructure/selectors/infrastructure';
import { selectActiveInfrastructureTypes } from 'shared/mf-infrastructure/selectors/infrastructure/selectActiveInfrastructureTypes';
import { selectInfrastructureCenterCoordinates } from 'shared/mf-infrastructure/selectors/infrastructure/selectInfrastructureCenterCoordinates';
import { selectInfrastructureGeoObjectsByYearMap } from 'shared/mf-infrastructure/selectors/infrastructure/selectInfrastructureGeoObjectsByYearMap';
import { selectSimilarNewbuildingsApiResult } from 'shared/mf-infrastructure/selectors/similarNewbuildings';
import { infrastructureActions } from 'shared/mf-infrastructure/slices';
import { loadInfrastructure, loadSimilarNewbuildings, loadSimilarObjects } from 'shared/mf-infrastructure/thunks';
import { IInfrastructure, ISetInfrastrutureTypesPayload } from 'shared/mf-infrastructure/types/infrastructure';
import { ITAInfrastructureData, TTAInfrastructureType } from 'shared/mf-infrastructure/types/transportAccessibility';
import { getTransportAccessibilityInfrastructureMapByType } from 'shared/mf-infrastructure/utils/getTransportAccessibilityInfrastructureByType';

export const useInfrastructureProps = (): IInfrastructureProps => {
  const { profile, yandexApiKey, yandexSuggestApiKey } = useWidgetSettingsContext();
  const isDesktop = useIsDesktop();
  const dispatch = useAppDispatch();

  const infrastructureStatus = useSelector(selectInfrastructureStatus);
  const infrastructureByYearMap = useSelector(selectInfrastructureGeoObjectsByYearMap);
  const centeredCoordinates = useSelector(selectInfrastructureCenterCoordinates);
  const infrastructureTypes = useSelector(selectAvailableInfrastructureTypes);
  const currentTypes = useSelector(selectActiveInfrastructureTypes);
  const infrastructureData = useSelector(selectInfrastructureApiResult);
  const similarNewbuildingsData = useSelector(selectSimilarNewbuildingsApiResult);

  const mapRef = useRef<MapRef>(null);
  const transportAccessibilityInfrastructureByTypeRef = useRef(new Map<TTAInfrastructureType, ITAInfrastructureData>());
  const infrastructureRef = useRef<IInfrastructure>({
    regularInfrastructureItems: [],
    transportAccessibilityInfrastructure: undefined,
  });

  const [isMapLoaded, setIsMapLoaded] = useState(false);

  const suggestPosition = isDesktop ? 'bottom' : 'top';

  let controls: IControl[] = [
    {
      type: 'ZoomControl',
      onClick: () => {},
    },
  ];

  if (isDesktop) {
    controls = [
      ...controls,
      { type: 'PanoramaControl' },
      { type: 'RulerControl' },
      { type: 'FullscreenControl' },
      { type: 'GeolocationControl' },
    ];
  }

  const setInfrastructureType = useCallback(
    (payload: ISetInfrastrutureTypesPayload) => {
      dispatch(infrastructureActions.setTypes(payload));
    },
    [dispatch],
  );

  const handleMapLoaded = useCallback(() => setIsMapLoaded(true), []);

  const handleFetchSimilarObjects = useCallback(
    async ({ bbox }: IGeoObjectsFetchData): Promise<IGeoObject[]> => {
      try {
        const result = await dispatch(loadSimilarObjects(bbox)).unwrap();

        return transformSimilarObjectsToGeoObjects(result);
      } catch (error) {
        return [];
      }
    },
    [dispatch],
  );

  const handleFetchSimilarNewbuildings = useCallback(async (): Promise<IGeoObject[]> => {
    try {
      const result = similarNewbuildingsData
        ? similarNewbuildingsData
        : await dispatch(loadSimilarNewbuildings()).unwrap();

      if (result) {
        return transformSimilarNewbuildingsToGeoObjects(result);
      }

      return [];
    } catch (error) {
      return [];
    }
  }, [dispatch, similarNewbuildingsData]);

  const handleFetchInfrastructure = useCallback(
    async (fetchData: IGeoObjectsFetchData): Promise<IGeoObject[]> => {
      let data: TGetInfrastructureResponse_1 | null = infrastructureData;

      if (!data) {
        try {
          data = await dispatch(loadInfrastructure(profile)).unwrap();
        } catch (e) {
          // nomnom
        }
      }

      if (data === null) {
        return [];
      }

      const similarObjects = await handleFetchSimilarObjects(fetchData);
      const similarNewbuildings = await handleFetchSimilarNewbuildings();

      transportAccessibilityInfrastructureByTypeRef.current = getTransportAccessibilityInfrastructureMapByType(data);

      infrastructureRef.current = transformNewbuildingInfrastructure(data);

      return [...transformResultToGeoObjects(data), ...similarObjects, ...similarNewbuildings];
    },
    [
      infrastructureData,
      handleFetchSimilarNewbuildings,
      handleFetchSimilarObjects,
      centeredCoordinates,
      dispatch,
      profile,
    ],
  );

  return {
    apiKey: yandexApiKey,
    suggestApiKey: yandexSuggestApiKey,
    mapRef,
    isMapLoaded,
    centeredCoordinates,
    currentTypes,
    infrastructureByYearMap,
    infrastructureTypes,
    setInfrastructureType,
    handleMapLoaded,
    controls,
    suggestPosition,
    onGeoObjectsFetch: handleFetchInfrastructure,
    isInfrastructureFetched: infrastructureStatus === 'fulfilled',
    transportAccessibilityInfrastructureByTypeRef,
    infrastructureRef,
    transportAccessibilityRate: infrastructureData?.transportAccessibilityRating || null,
  };
};
