import { TGetRecommendationsNearNewbuildingResponse_1 } from 'shared/common/repositories/newbuilding-search/v1/get-recommendations-near-newbuilding';
import { ISimilarNewbuildingItem } from 'shared/mf-infrastructure/types/infrastructure';

export const transformSimilarNewbuildingsToInfrastructureItems = (
  result: TGetRecommendationsNearNewbuildingResponse_1,
): ISimilarNewbuildingItem[] => {
  const { newbuildings, modelVersion } = result;

  return newbuildings.map(newbuilding => ({
    type: 'similar_newbuildings',
    coordinates: newbuilding.coordinates,
    distance: newbuilding.distance,
    id: `similar_newbuilding_${newbuilding.id}`,
    imageUrl: newbuilding.imageUrl || null,
    minPrice: newbuilding.minPrice || null,
    modelVersion,
    name: newbuilding.name,
    newbuildingId: newbuilding.id,
    roomsSquareMeter: newbuilding.roomsSquareMeter || null,
    url: newbuilding.url,
  }));
};
