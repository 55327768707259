import {
  IGetRecommendationsNearNewbuildingRequest,
  TGetRecommendationsNearNewbuildingResponse_1,
  fetchGetRecommendationsNearNewbuilding,
} from 'shared/common/repositories/newbuilding-search/v1/get-recommendations-near-newbuilding';
import { restApiFunctionWrapper } from 'shared/common/utils/restApiWrapper/restApiWrapper';

export const fetchSimilarNewbuildings = restApiFunctionWrapper<
  IGetRecommendationsNearNewbuildingRequest,
  TGetRecommendationsNearNewbuildingResponse_1
>(fetchGetRecommendationsNearNewbuilding);
