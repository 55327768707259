import { IGeoObject } from '@cian/frontend-newbuilding-map-component/es/types';

import { TGetRecommendationsNearNewbuildingResponse_1 } from 'shared/common/repositories/newbuilding-search/v1/get-recommendations-near-newbuilding';
import { getGeoObject } from 'shared/mf-infrastructure/utils/getGeoObject';

import { transformSimilarNewbuildingsToInfrastructureItems } from './transformSimilarNewbuildingsToInfrastructureItems';

export const transformSimilarNewbuildingsToGeoObjects = (
  result: TGetRecommendationsNearNewbuildingResponse_1,
): IGeoObject[] => {
  return transformSimilarNewbuildingsToInfrastructureItems(result).map(getGeoObject);
};
