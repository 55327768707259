// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type {
  TGetInfrastructureRequest,
  TGetInfrastructureModel,
  IMappers,
  TGetInfrastructureResponse,
  TGetInfrastructureResponse_1,
  TGetInfrastructureResponseError,
} from './types';
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';

export const defaultConfig: TGetInfrastructureModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'infrastructure-caching',
  pathApi: '/v4/get-infrastructure/',
  requestType: 'json',
  interserviceAuthEnabled: true,
} as TGetInfrastructureModel;

export function createGetInfrastructureModel(parameters: TGetInfrastructureRequest): TGetInfrastructureModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetInfrastructureOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: TGetInfrastructureRequest;
}

export async function fetchGetInfrastructure<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: IGetInfrastructureOptions<TResponse200, TResponse400>): Promise<
  TGetInfrastructureResponse | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = await httpApi.fetch(createGetInfrastructureModel(parameters), config);
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as TGetInfrastructureResponse_1);
    }
    if (statusCode === 400) {
      return mappers[400](response as TGetInfrastructureResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TGetInfrastructureResponse;
}
